<template>
  <v-container
    id="usager-profile"
    fluid
    tag="section"
  >
    <v-row justify="">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('profil.mon_profile') }}
              <!-- <span class="body-1">— Oui vous !</span> -->
            </div>
          </template>
          <v-form>
            <v-container class="py-0">
              <v-row>
                <!-- <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="usager.nomUsager"
                    class="purple-input"
                    label="Nom d'usager"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="usager.courriel"
                    label="Courriel"
                    class="purple-input"
                  />
                </v-col> -->

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="usager.prenom"
                    :label="$t('general.prenom')"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="usager.nom"
                    :label="$t('general.nom_de_famille')"
                    class="purple-input"
                  />
                </v-col>

                <!-- <v-col cols="12">
                  <v-text-field
                    v-model="usager.adresse.rue"
                    label="Adresse"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usager.adresse.ville"
                    label="Ville"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usager.adresse.province"
                    label="Province"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usager.adresse.pays"
                    label="Pays"
                    class="purple-input"
                  />
                </v-col> -->

                <!-- <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usager.adresse.codePostal"
                    class="purple-input"
                    label="Code postal"
                  />
                </v-col> -->

                <!-- <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="motPasse"
                    label="Mot de passe"
                    class="purple-input"
                    type="password"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="motPasseValidation"
                    label="Mot de passe (validation)"
                    class="purple-input"
                    type="password"
                  />
                </v-col> -->

                <!-- <v-col cols="12">
                  <v-textarea
                    v-model="usager.presentation"
                    class="purple-input"
                    label="À propos de moi"
                  />
                </v-col> -->

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    class="mr-0"
                    @click="onSauvegarder()"
                  >
                    {{ $t('general.sauvegarder') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>

        <base-material-card
          v-if="listeComptesClients.length > 0"
          icon="mdi-account-outline"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('compteClient.compte_client') }}
              <!-- <span class="body-1">— Oui vous !</span> -->
            </div>
          </template>
          <v-select
            v-model="compteClientActif"
            :label="$t('profil.compte_client_actif')"
            :items="listeComptesClients"
            item-text="nom"
            return-object
            @change="onChangeClientActif"
          />
        </base-material-card>
      </v-col>
      <!--
      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          avatar="https://source.unsplash.com/featured/512x512?portrait"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              CEO / CO-FOUNDER
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ usager.firstName }} {{ usager.lastName }}
            </h4>

            <p class="font-weight-light grey--text">
              {{ usager.presentation }}
            </p>

            <v-btn
              color="success"
              rounded
              class="mr-0"
            >
              Follow
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'

  export default {
    data: () => ({
      usager: {
        roles: 'user',
        adresse: {
        },
      },
      motPasse: '',
      motPasseValidation: '',
      listeComptesClients: {},
      compteClientActif: undefined,
    }),
    mounted () {
      this.compteClientActif = this.$store.state.compteClient
      restApiService
        // .get(`/api/usagers/${this.$store.state.userInfo.id}`)
        .get('/api/webUser/infoUsager')
        .then((result) => {
          this.usager = result.data.usager
          this.listeComptesClients = result.data.listeComptesClients
        })
        .catch((erreur) => alert(erreur))
    },
    methods: {
      onSauvegarder () {
        restApiService
          .put('/api/usagers', this.usager)
          .then(() => {
            this.changerMotPasse()
          })
          .catch((erreur) => alert(erreur))
      },
      changerMotPasse () {
        if (this.motPasse) {
          restApiService
            .put('/api/users/motPasse/' + this.usager.nomUsager, { motPasse: this.motPasse })
            .then(() => {
            })
            .catch((erreur) => alert(erreur))
        }
      },
      onChangeClientActif () {
        this.$store.commit('SET_COMPTECLIENT', this.compteClientActif)
      },
    },
    //
  }
</script>
